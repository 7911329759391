import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/10-benefits-of-learning-a-new-language",
  "date": "2019-11-27",
  "title": "10 Benefits of Learning a New Language",
  "tags": ["tips"],
  "featuredImage": "feature.jpg",
  "excerpt": "There are so many reasons to learn a new language. From its cognitive to life-enriching benefits, developing a bilingual brain is a worthwhile experience that could transform your life, both personally and professionally. "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here are 10 benefits to learning languages:`}</p>
    <h2>{`1. Improve communication skills`}</h2>
    <p>{`Usually, when people mention the law of unintended consequences, it’s almost always negative.`}</p>
    <p>{`Learning another language, makes you pay more attention to how you use your own. `}</p>
    <p>{`The more you understand the how otehr languages work, the more likely you are to notice subtle similarities or differences between them. These are the sort of things monolinguists would have real trouble spotting.`}</p>
    <p>{`In other words, mastering a foreign language can you improve your grammar, writing and editing – not to mention becoming a better overall communicator in your mother tongue, whether that takes the form of dealing with work politics, writing a paper or resolving a family spat.`}</p>
    <h2>{`2. Increase happiness`}</h2>
    <p>{`Okay, it sounds like a grand claim to begin with. And yes, we know happiness isn’t exactly a clear metric you can measure.`}</p>
    <p>{`But check this out:`}</p>
    <p>{`There is growing evidence that tracking progress and accomplishing goals is linked to increased happiness. Isn’t that exactly what you get when you clearly see your progress with a new language?`}</p>
    <h2>{`3. Make better decisions`}</h2>
    <p>{`Two words: cognitive reserve. Bilingual – and multilingual – brains can make better decisions because of it. `}</p>
    <p>{`It has been argued that people who speak more than one language find it easier to find alternative solutions or ways around a problem than people who just speak one. `}</p>
    <h2>{`4. Treat your brain`}</h2>
    <p>{`What does learning new words have in common with eating sweets?`}</p>
    <p>{`You’ll be surprised:`}</p>
    <p>{`Both activate the same part of your brain, the ventral striatum - the brain’s pleasure centre.`}</p>
    <p>{`So go ahead and treat yourself to new words every day!`}</p>
    <p>{`Unlike eating candy, it’s something you can binge on without moderation!`}</p>
    <h2>{`5. Increase your IQ`}</h2>
    <p>{`Learning a language actually makes you smarter! Academically speaking, that is.`}</p>
    <p>{`You see, all bilinguals and multilinguists have a higher density of grey matter – dark tissue found in the brain and spinal cord – than monolingual individuals. `}</p>
    <p>{`What this means for bilinguals is heightened ‘sensory perception’ – in plain English, superior sight, hearing, feeling, moving, speaking… the list goes on. `}</p>
    <p>{`As you can imagine, all this has a massive impact on your IQ, as a recent study has shown.`}</p>
    <p>{`The message here is simple: when it comes to IQ, learning a language matters.`}</p>
    <h2>{`6. Stay sharper for longer`}</h2>
    <p>{`There is mounting evidence that learning more languages can stave off dementia.`}</p>
    <p>{`According to recent studies, the age for the first signs of dementia in monolingual adults is 71.4. For those who speak two or more languages, the first signs appear much later, at 75.5.`}</p>
    <p>{`In short, becoming bilingual is a sure way of future-proofing your brain. `}</p>
    <p>{`And if you’re not convinced, you need only look at the more immediate effects to understand how beneficial learning a language can be.`}</p>
    <p>{`For you’ll find bilingual children and adults are more able to extract and focus on useful information, and buckle down to the task at hand. `}</p>
    <p>{`It’s the very act of developing language skills that will increase your attention span by strengthening your brain’s frontal cortex.  `}</p>
    <h2>{`7. Get better career opportunities`}</h2>
    <p>{`According to a Confederation of British Industry survey, 74% of employers said multilingual employees were more attractive to their organisation.`}</p>
    <p>{`It goes without saying that additional skills, like a foreign language, can make you a more attractive candidate for a new job. Or, if you’re already happily employed, the ability to speak another language makes it easier for your employer to send you off to work internationally. `}</p>
    <h2>{`8. Become better at multitasking`}</h2>
    <p>{`Task- and device-switching – they’re skills you need to survive in this day and age, both personally and professionally.`}</p>
    <p>{`Luckily enough, for bilingual brains multitasking is as good as second nature, thanks to all the time they spend to-ing and fro-ing between one language and another. `}</p>
    <h2>{`9. Increase your creativity`}</h2>
    <p>{`Another positive outcome (in case we haven’t given you enough already!): improved creativity.`}</p>
    <p>{`After all, your language shapes how you perceive and view the world, so why not enlarge it by inviting a whole new world inside your head?`}</p>
    <p>{`Ultimately a bilingual brain is exactly where the ability to think outside the box, or creativity, comes from.`}</p>
    <h2>{`10. Enjoy more authentic travel experiences`}</h2>
    <p>{`Of course, who could mention the benefits of language learning without travel?`}</p>
    <p>{`There is no doubt about it: you won’t really live a truly authentic travel experience unless you can learn the language to socialise, break down barriers, and truly soak up the local life.`}</p>
    <p>{`The world will be your oyster!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      